<template>
  <v-dialog v-model="dialogfreight" persistent max-width="350px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-calculator</v-icon
                  >{{ $vuetify.lang.t("$vuetify.freight") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="12" sm="12">
                <v-text-field
                  style="color: black"
                  label="Domestic Freight"
                  prefix="¥"
                  v-model="myObj.shipping_cost1"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="12" sm="12">
                <v-text-field
                  style="color: black"
                  label="Overseas Freight"
                  prefix="¥"
                  v-model="myObj.shipping_cost2"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" lg="12" md="12" sm="12">
                <v-text-field
                  outlined
                  dense
                  style="color: black"
                  class="mt-4"
                  label="Total freight:"
                  prefix="¥"
                  v-model="totalYunFei"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="validate">{{
          $vuetify.lang.t("$vuetify.Confirm")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ViewFreight",
  props: { dialogfreight: Boolean, myObj: Object},
  data() {
    return {
      totalYunFei: 0
    };
  },
  methods: {
    cancelForm() {
      this.$emit("update:dialogfreight", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      this.$refs.form.validate() == true
        ? this.$emit("handleData", this.myObj)
        : console.log("false");
    },
  },
  watch:{
    'myObj.shipping_cost1' : function(val){
      this.totalYunFei = parseFloat(Number(val) + Number(this.myObj.shipping_cost2)).toFixed(2);
    },
    'myObj.shipping_cost2' : function(val){
      this.totalYunFei = parseFloat(Number(val) + Number(this.myObj.shipping_cost1)).toFixed(2);
    }
  }
};
</script>